// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性

$(function () {
  let SPNavArea = $("#SPNav").height();
  $("#SPNavArea").css({
    top: SPNavArea
  });
  $(document).on("click", "#SPNavBtn", function () {
    $("#SPNavArea").fadeToggle();
    $("#SPNavBtn").toggleClass('Close');
  });
  /*

  */

  let SubNavArea = $("#MainImage").offset().top;
  $(".SubNav").css({
    top: SubNavArea
  });
  $(document).on("click", ".SubNavOpen a", function () {
    if ($(this).hasClass('current')) {
      $(this).removeClass('current');
      $("#HeaderLogo").removeClass("SubNavOpen");
      $('.SubNav').stop(true).slideUp(300, function () {
      });
    } else {
      $('.SubNavOpen a').removeClass('current');
      $(this).addClass('current');
      $('.SubNav').stop(true).slideDown(300);
      $("#HeaderLogo").addClass("SubNavOpen");
      /*
      $('body').addClass('fixed');
      var targetCate = $(this).attr('data-mega');
      $('.dropInner').removeClass('show');
      $('#' + targetCate).addClass('show');
      $('.megaDrop').stop(true).slideDown(300);
      */
    }
    return false;
  });

  // SP

  $(document).on("click", ".SPSubNavOpen a", function () {
    if ($(this).hasClass('current')) {
      $(this).removeClass('current');
      $('.SPSubNav').stop(true).fadeOut(300, function () { });
    } else {
      $('.SPSubNavOpen a').removeClass('current');
      $(this).addClass('current');
      $('.SPSubNav').stop(true).fadeIn(300);
      /*
      $('body').addClass('fixed');
      var targetCate = $(this).attr('data-mega');
      $('.dropInner').removeClass('show');
      $('#' + targetCate).addClass('show');
      $('.megaDrop').stop(true).slideDown(300);
      */
    }
    return false;
  });
});
