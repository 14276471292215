const moment = require('moment');
require('jquery-ui/ui/widgets/tabs');
import 'owl.carousel';
import 'vegas';
import AOS from 'aos';

/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
    if ($("#MainImage").length) {
        $.getJSON('/inc/slide.json') // json読み込み開始
            .done(function (json) { // jsonの読み込みに成功した時
                console.log('成功');
                console.log(json);
                $("#MainImage .TopPage--MainImage__Slide .Images").vegas({
                    slides: json
                });
            })
            .fail(function () { // jsonの読み込みに失敗した時
                console.log('失敗');
            });
        /*
        .always(function () {
            console.log('必ず実行される');
        })*/
    }
    //AOS.init();
    if ($(".owl-carousel").length) {
        $('.owl-carousel').owlCarousel({
            loop: false,
            margin: 10,
            items: 3,
            nav: true,
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                641: {
                    items: 2
                },
                769: {
                    items: 3
                }
            }
        });
    }
    //URLのハッシュ値を取得
    var urlHash = location.hash;
    //ハッシュ値があればページ内スクロール
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
    }
    $("#tabs").tabs();
    $("#tabs").on('tabsactivate', function (event, ui) {
        window.location.hash = ui.tab.hash;
    })
    if ($("#Facility-Arena").length) {
        $("#Facility-Arena .Photo").vegas({
            slides: [
                { src: "assets/img/Outline_Arena.jpg" },
                { src: "assets/img/Outline_Arena_1.jpg" }
            ]
        });
    }
    if ($("#Facility-Kids").length) {
        $("#Facility-Kids .Photo").vegas({
            slides: [
                { src: "assets/img/Outline_Kids.jpg" },
                { src: "assets/img/Outline_Kids_1.jpg" },
                { src: "assets/img/Outline_Kids_2.jpg" },
                { src: "assets/img/Outline_Kids_3.jpg" },
                { src: "assets/img/Outline_Kids_4.jpg" },
                { src: "assets/img/Outline_Kids_5.jpg" }
            ]
        });
    }
    if ($("#Facility-Running").length) {
        $("#Facility-Running .Photo").vegas({
            slides: [
                { src: "assets/img/Outline_Running.jpg" },
                { src: "assets/img/Outline_Running_1.jpg" }
            ]
        });
    }
    if ($("#Facility-ConferenceRoom").length) {
        $("#Facility-ConferenceRoom .Photo").vegas({
            slides: [
                { src: "assets/img/Outline_ConferenceRoom.jpg" },
                { src: "assets/img/Outline_ConferenceRoom_1.jpg" }
            ]
        });
    }
    if ($("#Facility-Parking").length) {
        $("#Facility-Parking .Photo").vegas({
            slides: [
                { src: "assets/img/Outline_Parking.jpg" },
                { src: "assets/img/Outline_Parking_1.jpg" }
            ]
        });
    }
});
